html {
  /** This prevents horizontal flickering, which is caused by a conflict between
    * OnboardJS modal and popper.js used by material-ui
    */
  overflow: inherit !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --onboard-modal-z-index: 1200;
  --onboard-account-select-modal-z-index: 1210;
  --onboard-login-modal-z-index: 1210;
}

body > iframe {
  display: none;
}
